<template>
    <div class="mauntacont">
        <el-dialog :title="$t('devtable.tjjsgly')" center append-to-body width="54%" label-width="110px" :visible.sync="dialogFormVisible">
           <el-form ref="form" :model="form" label-width="110px">

               <el-row >
                   <el-col :span="10">
                       <el-form-item :label="$t('devtable.sjhm')">
                            <el-input v-model="form.mobile"></el-input>
                        </el-form-item>
                   </el-col>
               </el-row>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveResult">{{$t('devtable.bcz')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Select from '@/components/selectree/wltreeselect'
import methodlist from '../../../utils/methods'

export default {
    components:{
        Select
    },    
    data () {
        return {
            id:'',
            opTypeFlag: 0,
            form: {
                mobile: ''
            },
            editId: '',
            dialogFormVisible: false,
            
        }
    },
    mounted () {
       this.UserInfo = JSON.parse(window.localStorage.getItem('user')); 
    },
    methods: {
       
        saveResult(){
            this.addNewManager();
            
        },
        addNewManager(){
            const params = {
                method: 'co.agent.manager.add',
                agent_id: this.UserInfo.agent_id,             
                mobile: this.form.mobile
            }
            this.$serve(params).then(res => {
                if(res.data.code == 0){
                    this.$message({
                        message: this.$t('devtable.tjcg'),
                        type: 'success'
                    })
                    this.dialogFormVisible = false;
                    this.$emit("addManagerSuccess");
                }else{
                    if(res.data.code == -4){
                        this.$message({
                            message: this.$t('devtable.tjsbxmycz'),
                            type: 'warning'
                        }) 
                    }else{
                        this.$message({
                            message: this.$t('devtable.tjsb'),
                            type: 'warning'
                        }) 
                    }
                }
            })
        },
    }
}
</script>
<style lang="less" scoped>
.s{
   
}
</style>